import PropTypes from "prop-types";
import React from "react";

const Button = ({ children, className, ghost, onClick, ...rest }) => {
  return (
    <button
      className={`btn ${ghost && "btn-ghost"} ${className}`}
      onClick={onClick}
      {...rest}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  ghost: PropTypes.bool,
  onClick: PropTypes.func
};

export default Button;
