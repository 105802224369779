import { Link } from "gatsby";
import React from "react";

function Footer() {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-12 md:mt-8">
      <div className="col-span-1 col-start-1">
        <h3 className="text-white mb-3">Biver</h3>
        <Link to="/recursos">
          <p className="text-white text-base mb-2 navbar">Recursos</p>
        </Link>
        <Link to="/planos">
          <p className="text-white text-base mb-2 navbar">Planos</p>
        </Link>
        <Link to="/biver">
          <p className="text-white text-base mb-2 navbar">Por que Biver?</p>
        </Link>
        <Link to="/contato">
          <p className="text-white text-base mb-2 navbar">Contato</p>
        </Link>
      </div>
      <div className="col-span-1 xl:col-start-2">
        <h3 className="text-white mb-3">Ajuda</h3>
        <Link to="https://bivercrm.com.br/blog/">
            <p className="text-white text-base mb-2 navbar">Blog</p>
          </Link>
        <a
          href="http://suporte.softin.com.br:443/"
          rel="noopener noreferrer"
          target="_blank"
        >
          <p className="text-white text-base mb-2 navbar">Suporte</p>
        </a>
        <p className="hidden text-white text-base mb-2">Central de ajuda</p>
      </div>
      <div className="col-span-1 xl:col-start-3">
        <h3 className="text-white mb-3">Biver CRM</h3>
        <Link to="https://bivercrm.com.br">
          <p className="text-white text-base mb-2 navbar">Sobre</p>
          </Link>
        <Link to="https://bivercrm.com.br/recursos">
          <p className="text-white text-base mb-2 navbar">Recursos</p>
        </Link>
        <Link to="https://bivercrm.com.br/planos">
          <p className="text-white text-base mb-2 navbar">Planos</p>
        </Link>
      </div>
      <div className="col-span-1 xl:col-start-4">
        <h3 className="text-white mb-3">Contato</h3>
        <div className="flex mb-2">
          <box-icon name="phone" color="#fff" type="solid" />
          <p className="flex text-white text-base items-center ml-2">
            (47) 3437-3312
          </p>
        </div>
        <div className="flex mb-2">
          <box-icon name="whatsapp" type="logo" color="#fff" />
          <p className="flex text-white text-base items-center ml-2">
            (47) 99134-2776
          </p>
        </div>
        <div className="flex mb-2">
          <box-icon name="envelope" color="#fff" />
          <p className="flex text-white text-base items-center ml-2">
            comercial@biverfranquias.com.br
          </p>
        </div>
        <div className="flex">
          <a
            href="https://www.facebook.com/bivercrmfranquias"
            rel="noopener noreferrer"
            target="_blank"
          >
            <box-icon name="facebook" type="logo" color="#fff" />
          </a>
          <a
            className="ml-2"
            href="https://www.instagram.com/biver_crm_franquias/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <box-icon name="instagram" type="logo" color="#fff" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
