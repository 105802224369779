import PropTypes from "prop-types";
import React from "react";
import { useModal } from "react-morphing-modal";
import TestModal from "../components/TestModal";

const ButtonTestModal = ({ children, className, ...rest }) => {
  const { modalProps, getTriggerProps } = useModal({
    background: "#fafafa"
  });
  return (
    <span>
      <TestModal modalProps={modalProps} />
      <button className={`btn ${className}`} {...getTriggerProps()} {...rest}>
        {children}
      </button>
    </span>
  );
};

ButtonTestModal.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

export default ButtonTestModal;
